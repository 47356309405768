import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import Consultation from "../components/free-consultation"

const SecondPage = () => (
  <Layout>
    <Seo title="About Us" />
    <Container className="singleCol">
    <h1>Jim Kautz, Architect</h1>
    <Row>
      <Col lg={7}>
        <p>
          Hi, I'm Jim Kautz, an architect who loves helping local homeowners design the home of their dreams. I’ve practiced architecture for over 40 years, and I designed everything from adding a bedroom to managing large scale hospital projects. For the last 11 years I've had the pleasure of owning my own firm helping people make their home more functional for  their current needs as well as more beautiful.
        </p>
      </Col>
      <Col lg={5} className="text-center">
        <p>
          <StaticImage 
            src="../images/jim-kautz.jpg"
            alt="Jim Kautz"
            placeholder="blurred"
          />
        </p>
      </Col>
    </Row>
    <p className="lead text-center"><strong>I can assist homeowners at every stage of the design and construction process.</strong>
</p>
    
    <p>It is most important to achieve a good conceptual plan before progressing to details and construction documents.  I believe in providing, and discussing, carefully-sketched options throughout the process.  For important decisions, such as a kitchen or new entryway, I provide 3-dimensional drawings so that my clients clearly understand their choices.</p>
    </Container>
    <Container className="text-center">
      <p>
        <StaticImage 
          src="../images/about/2-ross-entry-concept_orig.png"
          alt="Entry Way of House"
          placeholder="blurred"
        />
      </p>
      <p><small>A recent client wanted to move their front entry from the 2nd floor to the 1st floor. ​I provided a 3D concept to demonstrate what's possible within their budget.</small></p>
      <p>
        <StaticImage 
          src="../images/about/1-ross-existing-condition.jpg"
          alt="Entry Way of House"
          placeholder="blurred"
        />
      </p>
    </Container>
    <Container className="singleCol">
      <p>
        Once the conceptual plan is agreed, I work with building contractors, structural engineers, heating, plumbing and electrical experts, and interior designers to complete the design.  ​
      </p>
      <p>
        Lighting is a very important element of every successful design and I try to optimize both natural lighting by careful placement of windows and skylights and artificial lighting.
      </p>
    </Container>
    <Container>
      <Row>
        <Col lg={6}>
          <p>
            <StaticImage 
              src="../images/about/3-ross-kitchen-looking-out_orig.png"
              alt="Drawing of interior of house"
              placeholder="blurred"
            />
          </p>
        </Col>
        <Col lg={6}>
          <p>
            <StaticImage 
              src="../images/about/4-rosskitchen-to-new-familyrm_orig.png"
              alt="Drawing of interior of house"
              placeholder="blurred"
            />
          </p>
        </Col>
      </Row>
    </Container>

    <Container className="singleCol">
      <p>New energy codes require considering different lighting and I am conversant with the most up-to-date options. When proposed modifications would change the exterior of the building, I work with neighbors and the city planning department to obtain design review approvals. </p>
      <p>
      I create construction documents that enable reliable pricing and efficient construction.  I have worked closely with multiple residential contractors in Marin County to tailor projects to my clients’ budgets.  During construction, I am available to answer questions and to promote construction in accordance with good practices and the project plans.  
      </p>
      <hr />
    </Container>

    <div className="mt-3">
      <Consultation  />
    </div>
  </Layout>
)

export default SecondPage
